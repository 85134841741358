// src/components/Actions.js
import { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { GlobalStateContext } from "../GlobalStateContext";
import PasswordReset from "./PasswordReset";
import Authenticate from "./Authenticate";

const Actions = () => {
  const location = useLocation();
  const history = useHistory();
  const [mode, setMode] = useState("");

  const { setErrorMessage } = useContext(GlobalStateContext);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const mode = queryParams.get("mode");
    if (!mode || !["verifyEmail", "resetPassword"].includes(mode)) {
      setErrorMessage("Invalid action. Redirecting to the home page.");
      history.push("/");
    } else {
      setMode(mode);
    }
  }, [location, history, setErrorMessage]);

  const renderComponent = () => {
    switch (mode) {
      case "verifyEmail":
        return <Authenticate />;
      case "resetPassword":
        return <PasswordReset />;
      default:
        return null;
    }
  };

  return <>{renderComponent()}</>;
};

export default Actions;
