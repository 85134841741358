import { FaLock, FaQuestion } from "react-icons/fa";
import { FaScaleUnbalanced } from "react-icons/fa6";

export default function Sidebar() {
  // drop shadow only on the right side
  return (
    <div className="hidden md:block min-w-[250px] shadow-[4px_0_10px_rgba(0,0,0,0.1)]">
      <div className="py-5 flex flex-col mx-7">
        <h1 className="text-gray-500">Main</h1>
        <div className="flex flex-col h-full">
          <a
            href="/terms"
            className="flex items-center justify-start font-semibold px-3 py-2 rounded-md hover:bg-gray-200 transition-all"
          >
            <FaScaleUnbalanced />
            <span className="ml-2">Terms of Service</span>
          </a>
          <a
            href="/privacy"
            className="flex items-center justify-start font-semibold px-3 py-2 rounded-md hover:bg-gray-200 transition-all"
          >
            <FaLock />
            <span className="ml-2">Privacy Policy</span>
          </a>
          <a
            href="/support"
            className="flex items-center justify-start font-semibold px-3 py-2 rounded-md hover:bg-gray-200 transition-all"
          >
            <FaQuestion />
            <span className="ml-2">Support</span>
          </a>
        </div>
      </div>
    </div>
  );
}
