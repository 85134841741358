import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import "./css/index.css";
import Navbar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import Landing from "./pages/Landing";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfService from "./pages/TermsOfService";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import { GlobalStateProvider } from "./GlobalStateContext";
import Setup from "./pages/Setup";
import Support from "./pages/Support";
import Actions from "./pages/Actions";

function App() {
  return (
    <GlobalStateProvider>
      <Router>
        <Navbar />
        <div className="flex flex-row">
          <Sidebar />
          <Switch>
            <Route path={["/setup"]} exact component={Setup} />
            <Route path={["/"]} exact component={Landing} />
            <Route path={["/support"]} exact component={Support} />
            <Route path={["/sign-up"]} exact component={SignUp} />
            <Route path={["/login"]} exact component={Login} />
            <Route path={["/actions"]} exact component={Actions} />
            <Route
              path={[
                "/terms",
                "/terms-of-service",
                "/tos",
                "/legal/terms",
                "/legal/terms-of-service",
                "/legal/tos",
              ]}
              exact
              component={TermsOfService}
            />
            <Route
              path={[
                "/privacy-policy",
                "/privacy",
                "/legal/privacy",
                "/legal/privacy-policy",
              ]}
              exact
              component={PrivacyPolicy}
            />
            <Redirect to="/"></Redirect>
          </Switch>
        </div>
      </Router>
    </GlobalStateProvider>
  );
}

export default App;
