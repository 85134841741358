const PrivacyPolicy = () => {
    return <div className="mx-[5%] py-[25px]">
        <h1 className="font-bold text-[30px]">Privacy Policy</h1>
        <h1 className="font-bold text-[20px]">Last Updated: Wednesday 23rd, 2024 at 4:25 PM</h1>
        <hr className="mt-[10px] mb-[20px]"></hr>
        <div>
            <p>
                We respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines
                how we collect, use, and safeguard the information you provide to us.
            </p>
            <h2 className="text-[25px] mt-[20px] mb-[8px]">1. Information We Collect</h2>
            <p>
                We may collect and store certain information that allows us to access data for temporary checks. This
                information may include:
            </p>

            <ul>
                <li>- <b>Device Information</b>: We may collect information about the device you use to access our services, such as your
                    device's unique identifier, operating system, and browser type.</li>
                <li>- <b>Log Data</b>: When you interact with our services, our servers may automatically record certain information,
                    including your IP address, the date and time of your visit, and the pages you access.</li>
                <li>- <b>Discord Information</b>: We may collection public information about your Discord account, that being your username, id, and time your account was created.</li>
            </ul>

            <h2 className="text-[25px] mt-[20px] mb-[8px]">2. Use of Information</h2>
            <p>
                We <b>do not</b> store personal information. The information we collect is used solely for the purpose of temporary
                data checks. This may include ensuring the proper functioning of our services, analyzing trends, and improving
                our website's performance.
            </p>

            <h2 className="text-[25px] mt-[20px] mb-[8px]">3. Sharing of Information</h2>
            <p>
                We <b>do not</b> sell, trade, or otherwise transfer your information to third parties. We may share aggregated,
                non-personal information with trusted partners for statistical analysis, but this information will not be linked
                to any individual.
            </p>

            <h2 className="text-[25px] mt-[20px] mb-[8px]">4. Security</h2>
            <p>
                We take reasonable steps to protect the information we collect from unauthorized access, disclosure, alteration,
                or destruction. However, please be aware that no method of transmission over the internet or electronic storage
                is entirely secure, and we cannot guarantee the absolute security of your data.
            </p>

            <h2 className="text-[25px] mt-[20px] mb-[8px]">5. Cookies</h2>
            <p>
                Cookies are required to use Halo+. Without it, the app will not function.
            </p>

            <h2 className="text-[25px] mt-[20px] mb-[8px]">6. Updates to this Privacy Policy</h2>
            <p>
                We may update this <a href="/privacy-policy" className="text-[#a945b2] underline">Privacy Policy</a> from time to time to reflect changes in our practices or for other
                operational, legal, or regulatory reasons. Please check this page periodically for updates.
            </p>

            <h2 className="text-[25px] mt-[20px] mb-[8px]">7. Contact Us</h2>
            <p>
                If you have any questions or concerns about our <a href="/privacy-policy" className="text-[#a945b2] underline">Privacy Policy</a> or the data we collect, please contact us <a href="/discord" className="text-[#a945b2] underline">here</a>.
            </p>

            <p>
                By using Halo+, you consent to the terms of this <a href="/privacy-policy" className="text-[#a945b2] underline">Privacy Policy</a>.
            </p>

        </div>
    </div>
};

export default PrivacyPolicy;