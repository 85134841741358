const Announcement = ({ title, description, timePosted }) => {
    return <div className="pr-4 p-2 w-[100%] h-[100%] bg-[#ffff] shadow-lg rounded mb-5">
        <div></div>
        <div className="p-4">
            <div className="flex flex-row justify-between items-center">
                <h1 className="font-bold text-[1.5rem] mb-[10px]">{title}</h1>
                <span className="font-semibold">Published: {formatDateTime(timePosted)}</span>
            </div>
            <hr className="mb-[10px]"></hr>
            <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
    </div>
};

function formatDateTime(date) {
    const options = { weekday: 'long', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    return new Intl.DateTimeFormat('en-US', options).format(date);
}

export default Announcement;