import loginPreview from "../images/login_preview.png";
import downloadExtension from "../images/download_extension.png";
import loginToHaloPlus from "../images/login_to_haloplus.png";
import syncPreview from "../images/sync_preview.png";

export default function Setup() {
  return (
    <div className="m-14">
      <h1 className="text-5xl font-bold text-center">Setting up Halo+</h1>
      <div className="flex flex-col md:flex-row items-start justify-center mt-3 space-x-5">
        <div className="md:w-1/2 max-w-xl mt-5">
          <h1 className="text-2xl text-center">Installing with iOS</h1>
          <strong>1. Install Halo+ in the App Store</strong>
          <br />
          Open the App Store on your iOS device and search for "Halo+". Tap the
          download button to install the application.
          <br />
          <i>
            <a
              href="https://apps.apple.com/us/app/halo/id6502830905"
              className="underline"
            >
              Alternatively, you can download the app here.
            </a>
          </i>
          <br />
          <br />
          <strong>2. Registering an account</strong>
          <br />
          Once inside the app, click on the register button, and fill in your
          information according to the register requirements
          <br />
          <i>You are required to register with your verified GCU email.</i>
          <br />
          <br />
          <strong>3. Verifying your email</strong>
          <br />
          You should receive a link that sends you to verify your email address.
          Go ahead and use your login to verify the address.
          <br />
          <br />
          <strong>4. Download our Chrome Extension</strong>
          <br />
          Head onto the Chrome Web Store and search for{" "}
          <a
            href="https://chromewebstore.google.com/detail/halo+-sync/gmbglfgocfeknkbkalalejggdggemjhb"
            className="underline"
          >
            "Halo+ Sync"
          </a>
          . Click on the "Add to Chrome" button to install the extension.
          <br />
          <img
            src={downloadExtension}
            alt="Download Chrome extension for Halo+"
          ></img>
          <br />
          <strong>5. Head to Halo Portal</strong>
          <br />
          In order for us to sync your data, you need to head to the Halo Portal
          and login with your GCU email. You can find the portal at{" "}
          <a href="https://halo.gcu.edu" className="underline">
            https://halo.gcu.edu/
          </a>
          .<br />
          <img
            alt="A preview of the Halo Portal login screen."
            src={loginPreview}
          ></img>
          <br />
          <strong>6. Login into Halo+</strong>
          <br />
          Once you have installed the extension, you must log in with your GCU
          email and password before syncing your data.
          <br />
          <img src={loginToHaloPlus} alt="Login screen for Halo+"></img>
          <br />
          <strong>7. Sync your data</strong>
          <br />
          Once you have logged in, click on the "Sync Data" button to sync your
          data.
          <br />
          <img
            src={syncPreview}
            alt="Preview of data sync screen on Halo+"
          ></img>
          <strong>8. Open the app</strong>
          <br />
          You are now ready to use Halo+ on your iOS device. Just login with
          your GCU email and password and you're done!
          <br />
        </div>
        <div className="md:w-1/2 max-w-xl mt-5">
          <h1 className="text-xl text-center">Installing with Android</h1>
          <p className="mt-5">
            <strong>1. Install Halo+ with Firebase App Distribution</strong>
            <br />
            <i>
              <a
                href="https://appdistribution.firebase.dev/i/d62122517372a2f9"
                className="underline"
              >
                Download our application here.
              </a>
            </i>
            <br />
            <br />
            <strong>2. Registering an account</strong>
            <br />
            Once inside the app, click on the register button, and fill in your
            information according to the register requirements
            <br />
            <i>You are required to register with your verified GCU email.</i>
            <br />
            <br />
            <strong>3. Verifying your email</strong>
            <br />
            You should receive a link that sends you to verify your email
            address. Go ahead and use your login to verify the address.
            <br />
            <br />
            <strong>4. Download our Chrome Extension</strong>
            <br />
            Head onto the Chrome Web Store and search for{" "}
            <a
              href="https://chromewebstore.google.com/detail/halo+-sync/gmbglfgocfeknkbkalalejggdggemjhb"
              className="underline"
            >
              "Halo+ Sync"
            </a>
            . Click on the "Add to Chrome" button to install the extension.
            <br />
            <img
              src={downloadExtension}
              alt="Download Chrome extension for Halo+"
            ></img>
            <br />
            <strong>5. Head to Halo Portal</strong>
            <br />
            In order for us to sync your data, you need to head to the Halo
            Portal and login with your GCU email. You can find the portal at{" "}
            <a href="https://halo.gcu.edu" className="underline">
              https://halo.gcu.edu/
            </a>
            .<br />
            <img
              alt="A preview of the Halo Portal login screen."
              src={loginPreview}
            ></img>
            <br />
            <strong>6. Login into Halo+</strong>
            <br />
            Once you have installed the extension, you must log in with your GCU
            email and password before syncing your data.
            <br />
            <img src={loginToHaloPlus} alt="Login screen for Halo+"></img>
            <br />
            <strong>7. Sync your data</strong>
            <br />
            Once you have logged in, click on the "Sync Data" button to sync
            your data.
            <br />
            <img
              src={syncPreview}
              alt="Preview of data sync screen on Halo+"
            ></img>
            <strong>8. Open the app</strong>
            <br />
            You are now ready to use Halo+ on your Android device. Just login
            with your GCU email and password and you're done!
            <br />
          </p>
        </div>
      </div>
    </div>
  );
}
