import axios from "axios";
import Announcement from "../components/Announcement";
import PurpleBanner from '../images/banner_purple.png';
import { useEffect, useState, useContext } from "react";
import { GlobalStateContext } from "../GlobalStateContext";

const Landing = () => {
  const [announcements, setAnnouncements] = useState([]);
  const { errorMessage, setErrorMessage } = useContext(GlobalStateContext);

  useEffect(() => {
    axios.get("https://halopl.us/api/announcements").then((re) => {
      setAnnouncements(re.data);
    });
  }, []);

  const handleCloseModal = () => {
    setErrorMessage("");
  };

  return (
    <div className="mx-[5%] my-[25px] flex flex-col items-center justify-center">
      <img src={PurpleBanner} alt="Halo+ Banner" width={250} height={250}></img>
      <h1 className="font-bold text-[30px]">Announcements</h1>
      {announcements.map((announcement) => (
        <Announcement
          key={announcement.id}
          timePosted={announcement.time_posted}
          title={announcement.title}
          description={announcement.description}
        />
      )).reverse()}
      {errorMessage && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded shadow-md w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Error</h2>
            <p>{errorMessage}</p>
            <button
              onClick={handleCloseModal}
              className="mt-4 p-2 bg-red-500 text-white font-bold rounded"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Landing;
