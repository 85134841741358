import { useState, useEffect, useContext } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { auth } from "../firebase";
import { signInWithEmailAndPassword, applyActionCode } from "firebase/auth";
import { GlobalStateContext } from "../GlobalStateContext";

const Authenticate = () => {
  const location = useLocation();
  const history = useHistory();
  const { setErrorMessage } = useContext(GlobalStateContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [oobCode, setOobCode] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("oobCode");

    if (code) {
      setOobCode(code);
    } else {
      setErrorMessage("Invalid or missing authentication code.");
      history.push("/");
    }
  }, [location, setErrorMessage, history]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      signInWithEmailAndPassword(auth, email, password);
      if (oobCode) {
        await applyActionCode(auth, oobCode);
        setMessage("Email verified successfully!");
      }
    } catch (error) {
      setMessage("Login failed. Please try again.");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="bg-white p-8 rounded shadow-md w-full max-w-md">
        <h1 className="text-2xl font-bold mb-4 text-center">Authenticate</h1>
        <form onSubmit={handleLogin} className="flex flex-col gap-4">
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="p-2 border border-gray-300 rounded"
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="p-2 border border-gray-300 rounded"
          />
          <button
            type="submit"
            className="p-2 bg-blue-500 text-white font-bold rounded"
          >
            Login
          </button>
        </form>
        {message && <p className="mt-4 text-center">{message}</p>}
      </div>
    </div>
  );
};

export default Authenticate;
